import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import trust from "../assets/image/trust.png";
import bannerVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM.mp4";
import afutata from "../assets/image/afutata.png";
import bcd from "../assets/image/bcd.png";
import ezmuv from "../assets/image/ezmuv.png";
import fcc from "../assets/image/fcc.png";
import onroutz from "../assets/image/onroutz.png";
import rbc from "../assets/image/rbc.png";
import rtaxi from "../assets/image/rtaxi.png";
import trident_taxi from "../assets/image/trident_taxi.png";
import usa_luxury from "../assets/image/usa_luxury.jpeg";
import az_transfer from "../assets/image/az_transfer.jpeg";

const InfoSection = () => {
  const clientLogo = [
    { image: afutata },
    { image: fcc },
    { image: bcd },
    { image: ezmuv },
    { image: onroutz },
    { image: rbc },
    // { image: rtaxi },
    { image: az_transfer },
    { image: trident_taxi },
    { image: usa_luxury },
  ];

  return (
    <div className="services">
      <Container>
        <h3 className="text-center hav-bg">
          <span style={{ color: "#000" }}>
            All in One Highly Trusted Robust Cloud Limo & Chauffeur Dispatch
            System
            <div className="content">
              <p>
                We provide you all in one cloud Limo & Chauffeur Dispatch system
                with all the technical resources. required from start to finish
                to run your application.Our Dispatch system allows you to track
                your drivers and monitor the jobs and payment activity in
                real-time
              </p>
            </div>
            <div className="recent-client-div">
              <p style={{ color: "#000", marginBottom: "20px" }}>
                Our Recent Client
              </p>
              <div className="recent-logo-ctn">
                {clientLogo.map((item, ind) => (
                  <div className="single-image" key={ind}>
                    <img
                      style={{ borderRadius: "5px" }}
                      src={item.image}
                      alt="cars"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div style={{ borderTop: "1px solid #ccdaf9" }}>
              <a
                href="https://www.trustpilot.com/review/atozdispatch.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ marginBottom: "10px", marginTop: " 30px" }}
                  src={trust}
                  alt="trustpilot"
                />
              </a>
            </div>
          </span>
        </h3>
        <div className="arrow">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="592.000000pt"
            height="421.000000pt"
            viewBox="0 0 592.000000 421.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,421.000000) scale(0.100000,-0.100000)"
              fill="#54A937"
              stroke="none"
            >
              <path
                d="M1301 3921 c-17 -11 -21 -9 199 -106 220 -98 378 -177 511 -256 659
-393 1141 -938 1436 -1624 58 -133 107 -271 167 -465 32 -102 65 -201 73 -220
l15 -35 10 25 c25 64 -24 394 -92 620 -57 191 -102 302 -200 495 -146 288
-301 505 -529 739 -323 330 -721 586 -1141 734 -172 60 -419 111 -449 93z"
              />
              <path
                d="M4585 2323 c-46 -63 -259 -494 -323 -653 -162 -403 -254 -802 -269
-1168 -3 -84 -9 -151 -13 -150 -4 2 -105 116 -225 253 -338 387 -464 524 -675
735 -186 186 -273 261 -287 247 -29 -30 350 -449 883 -977 l328 -325 8 55 c57
390 146 699 347 1213 183 464 298 817 267 817 -3 0 -21 -21 -41 -47z"
              />
            </g>
          </svg>
        </div>

        <div className="video-wrapper hide-in-small">
          <video width="100%" className="video-frame" controls={true}>
            <source src={bannerVideo} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </div>
      </Container>
    </div>
  );
};

export default InfoSection;
