import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsGlobeEuropeAfrica } from "react-icons/bs";
import { postRequest } from "../../Helpers/APIHelper";

const NewContactUs = () => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("send-contact-us", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          alert(response?.message ?? "");
          window.location.reload();
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <div style={{ background: "#EFF2F7" }}>
      <Container>
        <div className="contact-form">
          <h3>Request Demo</h3>
          <div className="form-container">
            <iframe
              title="iframe-contact"
              width="3000"
              height="300"
              src="https://crm.zoho.com/crm/WebFormServeServlet?rid=9782a1926f1915385d44f182401c0386d62ae7494503a7299775296675b05664dfc1219f15e0ba647bdeca0d4f0e872agid8104cf5d2a707488413e13b053265d7961ca7ee73ba15382449b5afb7acad80a"
            ></iframe>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewContactUs;
