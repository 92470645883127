import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

import { Collapse } from "antd";
import CustomCollapse from "./collapse-component/CustomCollapse";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const navigate = useNavigate();

  const textStyle = {
    color: "#fff",
    textAlign: "left",
    fontWeight: 500,
    paddingLeft: "20px",
  };

  const headerStyle = {
    color: "#fff",
    textAlign: "left",
    fontWeight: 500,
    fontSize: "16px",
    color: "#ec6408",
  };

  return (
    <>
      <div className="contact">
        <Container>
          <Row>
            <Col md={5}>
              <div className="contact-left">
                <p className="p1">Support</p>
                <h2 className="h2">FAQs</h2>
                <p className="p2">
                  Everything you need to know about the plans and billing. Can't
                  find the answer you're looking for?
                </p>
                <p className="p3">Please Contact Us</p>
              </div>
            </Col>
            <Col md={7}>
              <CustomCollapse textStyle={textStyle} headerStyle={headerStyle} />
              <button
                onClick={() => navigate("/faq")}
                style={{
                  padding: "10px 7px ",
                  boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                className="header-outlined-btn"
              >
                View More
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default FAQ;
