import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, Spin } from "antd";
import UploadButton from "./UploadButton";
import AgrementModal from "../../components/modal/AgrementModal";
import ThankYouModal from "../../components/modal/ThankYouModal";
import { Link } from "react-router-dom";
import { PostMethod } from "../../Helpers/PostMethod";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const SignupForm = () => {
  // Stripe setup
  const stripe = useStripe();
  const elements = useElements();
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  // Component states
  const [open, setOpen] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState("");
  const [signatureImg, setSignatureImg] = useState(null);
  const [cardTokenInfo, setCardTokenInfo] = useState({
    card_token: null,
    card_id: null,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    business_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    plan: "Standard",
    signature: signatureImg,
    business_logo: [],
    card_id: null,
    card_token: null,
  });
  const [agreementInfo, setAgreementInfo] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    plan: "",
  });

  const defaultValues = {
    name: "",
    email: "",
    business_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    plan: "Standard",
    signature: "",
    business_logo: [],
  };

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    setValue("plan", "Standard");
  }, [setValue]);

  const handleClose = () => setOpen(false);
  const handleThankClose = () => setOpenThankYou(false);
  const handleChange = (event) => setIsChecked(event.target.checked);

  const prepareFormData = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  };

  const createCardToken = async () => {
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (error) throw new Error(error.message);
    if (token) {
      setCardTokenInfo({ card_token: token.id, card_id: token.card.id });
      setFormData((prev) => ({
        ...prev,
        card_token: token.id,
        card_id: token.card.id,
      }));
      return token;
    }
  };

  const onSubmit = async (data) => {
    setError("");
    try {
      const updatedData = {
        ...data,
        signature: signatureImg && signatureImg,
        ...(data?.domain_name != "" && { domain_name: data?.domain_name }),
      };
      setFormData(updatedData);
      setAgreementInfo({
        name: updatedData.name,
        phone: updatedData.mobile,
        email: updatedData.email,
        address: updatedData.country,
        city: updatedData.city,
        state: updatedData.state,
        country: updatedData.country,
        plan: updatedData.plan,
      });

      if (!cardTokenInfo.card_id && !cardTokenInfo.card_token) {
        await createCardToken();
      }

      if (!isAgreed) {
        setOpen(true);
        return;
      }

      setIsPending(true);
      const formData = prepareFormData(updatedData);
      formData.append("card_token", cardTokenInfo.card_token);
      formData.append("card_id", cardTokenInfo.card_id);

      const response = await PostMethod(`${apiEndpoint}/register`, formData);
      setIsPending(false);
      setOpenThankYou(true);
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || "An error occurred");
      setIsPending(false);
    }
  };

  const handleFinalSubmit = async (data) => {
    try {
      setIsPending(true);
      if (signatureImg) {
      }
      const dataToSend = prepareFormData(data);
      const response = await PostMethod(`${apiEndpoint}/register`, dataToSend);
      setIsPending(false);
      setOpenThankYou(true);
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message);
      setIsPending(false);
    }
  };

  const CARD_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "20px",
        "::placeholder": { color: "#aab7c4" },
        padding: "10px",
        border: "1px solid #000",
        borderRadius: "4px",
        backgroundColor: "#fff",
      },
      invalid: { color: "#fa755a", iconColor: "#fa755a" },
    },
    hidePostalCode: true,
    iconStyle: "solid",
  };

  console.log(process.env.REACT_APP_API_ENDPOINT);

  return (
    <div className="signupform-wrapper">
      <div className="custom-alert">
        <h3>30 DAYS FREE TRIAL</h3>
        <p>* You won't be charged Until Trial Period</p>
      </div>
      <form className="input-group-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="name">Name</label>
          <Controller
            name="name"
            id="name"
            control={control}
            rules={{ required: "Full Name is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.name ? "1px solid rgba(255, 0, 0, 0.712)" : "",
                }}
                type="text"
                placeholder="Name"
                {...field}
              />
            )}
          />
          {errors.name && (
            <p className="error-message">*{errors.name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="mobile">Phone</label>
          <Controller
            name="mobile"
            control={control}
            rules={{ required: "Phone Number is required" }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                country={"us"}
                value={field.value}
                onChange={(phone) => field.onChange("+" + phone)}
                className={` w-full ${
                  errors.phone
                    ? "border-red-500 border-[2px]"
                    : "border-[#cccccc] border-[1px]"
                } bg-light_gray mt-[5px] rounded-[4px]`}
              />
            )}
          />
          {errors.mobile && (
            <p className="error-message">*{errors.mobile.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <input
                type="email"
                style={{
                  border: errors.email
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                placeholder="johndoe@example.com"
                {...field}
              />
            )}
          />
          {errors.email && (
            <p className="error-message">*{errors.email.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_name">Business Name</label>
          <Controller
            name="business_name"
            control={control}
            rules={{ required: "Business Name is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.business_name
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="ABC Company"
                {...field}
              />
            )}
          />
          {errors.business_name && (
            <p className="error-message">*{errors.business_name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_address">Address</label>
          <Controller
            name="address"
            control={control}
            rules={{ required: "Business Address is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.address
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Business Address"
                {...field}
              />
            )}
          />
          {errors.address && (
            <p className="error-message">*{errors.address.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_name">City</label>
          <Controller
            name="city"
            control={control}
            rules={{ required: "City is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.city ? "1px solid rgba(255, 0, 0, 0.712)" : "",
                }}
                type="text"
                placeholder="city"
                {...field}
              />
            )}
          />
          {errors.city && (
            <p className="error-message">*{errors.city.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_name">State</label>
          <Controller
            name="state"
            control={control}
            rules={{ required: "State is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.state
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="State"
                {...field}
              />
            )}
          />
          {errors.state && (
            <p className="error-message">*{errors.state.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="country">Country</label>
          <Controller
            name="country"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <input
                style={{
                  border: errors.country
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Country"
                {...field}
              />
            )}
          />
          {errors.country && (
            <p className="error-message">*{errors.country.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="domain_name">Domain Name</label>
          <Controller
            name="domain_name"
            rules={{ required: "Domain is required" }}
            control={control}
            render={({ field }) => (
              <input
                style={{
                  border: errors.domain_name
                    ? "1px solid rgba(255, 0, 0, 0.712)"
                    : "",
                }}
                type="text"
                placeholder="Domain Name"
                {...field}
              />
            )}
          />
          {errors.domain_name && (
            <p className="error-message">*{errors.domain_name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="business_logo">Business Logo</label>
          <Controller
            name="business_logo"
            control={control}
            render={({ field }) => <UploadButton {...field} />}
          />
          {errors.business_logo && (
            <p className="error-message">*{errors.business_logo.message}</p>
          )}
        </div>

        <div className="package-plan">
          <h2>Choose Your Package</h2>
          <Controller
            name="plan"
            control={control}
            rules={{ required: "Please select a package plan" }}
            render={({ field }) => (
              <div className="plan1-ctn">
                <div
                  className="card"
                  onClick={() => field.onChange("Basic")}
                  style={{
                    border:
                      field.value === "Basic"
                        ? "2px solid green"
                        : "1px solid #ccc",
                  }}
                >
                  <input
                    type="radio"
                    value="Basic"
                    checked={field.value === "Basic"}
                    onChange={() => field.onChange("Basic")}
                    style={{ display: "none" }}
                  />
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Basic
                  </label>
                  <label style={{ fontSize: "22px", fontWeight: "800" }}>
                    $99{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        margin: "0px",
                        padding: "0px",
                        fontWeight: "normal",
                      }}
                    >
                      /month
                    </span>
                  </label>
                </div>
                <div
                  className="card"
                  onClick={() => field.onChange("Standard")}
                  style={{
                    border:
                      field.value === "Standard"
                        ? "2px solid green"
                        : "1px solid #ccc",
                  }}
                >
                  <input
                    type="radio"
                    value="Standard"
                    checked={field.value === "Standard"}
                    onChange={() => field.onChange("Standard")}
                    style={{ display: "none" }}
                  />
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Standard
                  </label>
                  <label style={{ fontSize: "22px", fontWeight: "800" }}>
                    $149{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        margin: "0px",
                        padding: "0px",
                        fontWeight: "normal",
                      }}
                    >
                      /month
                    </span>
                  </label>
                </div>
                <div
                  className="card"
                  onClick={() => field.onChange("Premium")}
                  style={{
                    border:
                      field.value === "Premium"
                        ? "2px solid green"
                        : "1px solid #ccc",
                  }}
                >
                  <input
                    type="radio"
                    value="Premium"
                    checked={field.value === "Premium"}
                    onChange={() => field.onChange("Premium")}
                    style={{ display: "none" }}
                  />
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Premium
                  </label>
                  <label style={{ fontSize: "22px", fontWeight: "800" }}>
                    $199{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        margin: "0px",
                        padding: "0px",
                        fontWeight: "normal",
                      }}
                    >
                      /month
                    </span>
                  </label>
                </div>
              </div>
            )}
          />
        </div>

        {/* -card-info-- */}
        <div>
          <label htmlFor="business_logo">Card</label>
          <div
            style={{
              border: "1px solid #d1d0d0",
              padding: "10px",
              borderRadius: "7px",
            }}
          >
            <CardElement options={CARD_OPTIONS} />
          </div>
        </div>
        {/* -card-info-- */}

        <div className="terms">
          <div>
            <input onChange={handleChange} type="checkbox" />
          </div>
          <p>
            I agree to{" "}
            <Link target="_blank" to={"/privacy-policy"}>
              Privacy Policy
            </Link>{" "}
            &{" "}
            <Link target="_blank" to={"/terms-of-service"}>
              Terms
            </Link>
          </p>
        </div>

        {error && (
          <p style={{ color: "red", fontStyle: "italic", fontSize: "15px" }}>
            *{error}
          </p>
        )}

        <button
          style={{
            textAlign: "center",
            justifyContent: "center",
            opacity: !isChecked ? "0.8" : "1",
          }}
          className="header-outlined-btn"
          disabled={!isChecked || isPending}
          type="submit"
        >
          {isPending ? (
            <span>
              <Spin /> Processing...{" "}
            </span>
          ) : (
            "30 Days Free Trial"
          )}
        </button>
      </form>

      <AgrementModal
        agreementInfo={agreementInfo}
        setIsAgreed={setIsAgreed}
        formData={formData}
        setFormData={setFormData}
        isAgreed={isAgreed}
        open={open}
        handleClose={handleClose}
        setSignatureImg={setSignatureImg}
        handleSubmission={handleFinalSubmit}
      />

      <ThankYouModal
        info={agreementInfo}
        open={openThankYou}
        handleClose={handleThankClose}
      />
    </div>
  );
};

export default SignupForm;
