import React from "react";
import Services from "../components/services";
import Header from "../components/header";
import NewBannerCard from "../components/new-banner-card/NewBannerCard";
import SubscriptionPlan from "../components/SubscriptionPlan";
import FAQ from "../components/FAQ";
import NewContactUs from "../components/contact-us/NewContactUs";
import NewFooter from "../components/NewFooter";
import BannerOne from "../components/BannerOne";
import InfoSection from "../components/InfoSection";
import WhatsappButton from "../components/WhatsappButton";
import JiraTriggerButton from "../components/JiraTriggerButton";

const Index = (props) => {
  return (
    <>
      <Header title={props.metaTitle} desc={props.metaContent} />
      <BannerOne />
      <NewBannerCard />
      <InfoSection />
      <SubscriptionPlan />
      <Services />
      <FAQ />
      <NewContactUs />
      <NewFooter />
      {/* <JiraTriggerButton /> */}
      {/* <WhatsappButton /> */}
    </>
  );
};
export default Index;
