import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/image/logo.svg";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { postRequest } from "../Helpers/APIHelper";
import { IoMdPlay } from "react-icons/io";
import introVideo from "../assets/videos/atoz-video-1.mp4";
import { MdWifiTethering } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { GoLightBulb } from "react-icons/go";
import { MdOutlineVideoCameraFront } from "react-icons/md";
import GoogleTranslate from "../GoogleTranslate";
import LiveDemoModal from "./modal/LiveDemoModal";

const Header = (props) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showLive, setShowLive] = useState(false);

  const handleCloseVideoModal = () => setShowVideoModal(false);
  const handleShowVideoModal = () => setShowVideoModal(true);

  const showModal = (link) => {
    handleShow();
  };

  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleClose = () => {
    setShow(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
  };

  const handleShow = () => {
    setMessage(false);
    setShow(true);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitDemo = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("request-a-demo", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          setMessage(true);
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <header>
        <Container fluid>
          <div className="navbar">
            <div className="right-part">
              <Link to="/">
                <img alt="stack overflow" src={logo}></img>
              </Link>
              <Link style={{ color: "#000" }} to="/">
                <span style={{ fontWeight: "bolder", fontSize: "22px" }}>
                  A to Z Dispatch
                </span>
              </Link>
            </div>
            <div className="left-part">
              <div
                style={{ display: "flex", justifyContent: "end" }}
                className="get-started"
              >
                <div className="hide-div-in-large">
                  <button
                    onClick={showModal}
                    style={{
                      background: "#24AD7A",
                      paddingRight: "14px",
                      paddingLeft: "14px",
                    }}
                    className="header-outlined-btn"
                  >
                    Request Demo
                  </button>
                  <button
                    onClick={() => navigate("/signup")}
                    style={{
                      padding: "10px 7px ",
                      boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                    }}
                    className="outline-variant"
                  >
                    Sign up
                  </button>
                </div>

                {/* ----this-will-show-in-large-screen-only------ */}
                <div className="hide-div-in-small">
                  <button onClick={showModal} className="outline-contained">
                    <GoLightBulb size={20} />
                    Request Demo
                  </button>
                  <button
                    onClick={() => navigate("/signup")}
                    className="outline-variant"
                  >
                    Sign up
                  </button>
                </div>
                <Modal
                  show={showVideoModal}
                  onHide={handleCloseVideoModal}
                  className="full-modal"
                >
                  <Button variant="secondary" onClick={handleCloseVideoModal}>
                    X
                  </Button>
                  <video
                    width="100%"
                    height="100%"
                    controls="false"
                    autoPlay="true"
                  >
                    <source src={introVideo} type="video/mp4" />
                  </video>
                </Modal>
              </div>
            </div>
          </div>
        </Container>
      </header>
      <div className="hide-in-large">
        <Link to={"/signup"}>
          <button className=" fixed-button header-fixed-btn ">
            SIGNUP FOR FREE TRIAL
          </button>
        </Link>
      </div>

      <Modal
        show={show}
        className="centered-modal"
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header>
          <a href="#!" className="close-modal" onClick={handleClose}>
            <FaTimes />
          </a>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="request-demo"
            width="3000"
            height="900"
            src="https://crm.zoho.com/crm/WebFormServeServlet?rid=9782a1926f1915385d44f182401c0386d62ae7494503a7299775296675b05664dfc1219f15e0ba647bdeca0d4f0e872agid8104cf5d2a707488413e13b053265d7961ca7ee73ba15382449b5afb7acad80a"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "100%",
              maxHeight: "85vh",
            }}
          ></iframe>
        </Modal.Body>
      </Modal>
      <LiveDemoModal open={showLive} handleClose={() => setShowLive(false)} />
    </>
  );
};
export default Header;
