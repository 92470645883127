import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import facebook from "../assets/image/facebook.png";
import twitter from "../assets/image/twitter.png";
import youtube from "../assets/image/youtube.png";
import tiktok from "../assets/image/tiktok.png";
import linkedin from "../assets/image/linkedin.png";
import instagram from "../assets/image/insta.png";
import trustpilot from "../assets/image/trustpilot.png";
import { FaPinterest } from "react-icons/fa";
import appStoreLogo from "../assets/image/App_Store_Icon.webp";
import googlePlayLogo from "../assets/image/Google_Play_Store_icon.png";
import rStar from "../assets/image/rstar.png";

const NewFooter = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    // Script for sd variant
    const scriptSD = document.createElement("script");
    scriptSD.async = true;
    scriptSD.src = "https://b.sf-syn.com/badge_js?sf_id=3707150&variant_id=sd";
    document
      .getElementsByTagName("script")[0]
      .parentNode.insertBefore(
        scriptSD,
        document.getElementsByTagName("script")[0]
      );

    // Script for sf variant
    const scriptSF = document.createElement("script");
    scriptSF.async = true;
    scriptSF.src = "https://b.sf-syn.com/badge_js?sf_id=3707150&variant_id=sf";
    document
      .getElementsByTagName("script")[0]
      .parentNode.insertBefore(
        scriptSF,
        document.getElementsByTagName("script")[0]
      );

    // Cleanup if needed
    return () => {
      document
        .getElementsByTagName("script")[0]
        .parentNode.removeChild(scriptSD);
      document
        .getElementsByTagName("script")[0]
        .parentNode.removeChild(scriptSF);
    };
  }, []);

  return (
    <section>
      <div className="footer-container">
        <div className="footer-section sitemap">
          <h4>A to Z Dispatch</h4>
          <ul>
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy policy</a>
            </li>
            <li>
              <a href="/cookies-policy">Cookie Policy</a>
            </li>
            <li>
              <a href="/contact-us">Contact US</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/faq">FAQs</a>
            </li>
          </ul>
          <div>
            <div className="download-section">
              <Link
                target="_blank"
                to={
                  "https://apps.apple.com/us/app/a-to-z-dispatch/id6639615450"
                }
              >
                <img
                  style={{
                    height: "55px",
                    width: "170px",
                    objectFit: "contain",
                  }}
                  width={200}
                  src={appStoreLogo}
                  alt="app-store"
                />
              </Link>
              <Link
                target="_blank"
                to={
                  "https://play.google.com/store/apps/details?id=com.a2z.dispatch"
                }
              >
                <img
                  // style={{ objectFit: "cover", width: "200px" }}
                  style={{
                    height: "55px",
                    width: "170px",
                    objectFit: "contain",
                  }}
                  width={200}
                  src={googlePlayLogo}
                  alt="google-play"
                />
              </Link>
              <Link
                target="_blank"
                to={
                  "https://www.saashub.com/a-to-z-dispatch?utm_source=badge&utm_campaign=badge&utm_content=a-to-z-dispatch&badge_variant=color&badge_kind=approved"
                }
              >
                <img
                  // style={{ objectFit: "cover", width: "200px" }}
                  style={{
                    height: "55px",
                    width: "170px",
                    objectFit: "cover",
                  }}
                  width={200}
                  src="https://cdn-b.saashub.com/img/badges/approved-color.png?v=1"
                  alt="google-play"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-section product">
          <div className="logo-section">
            <div
              className="sf-root"
              data-id="3707150"
              data-badge="users-love-us-new-white"
              data-variant-id="sd"
              style={{
                display: "block",
                textAlign: "right",
                width: "75px",
              }}
            >
              <a
                href="https://slashdot.org/software/p/A-to-Z-Dispatch/"
                target="_blank"
              >
                AtoZ Dispatch Reviews
              </a>
            </div>

            <div
              style={{
                display: "block",
                textAlign: "left",
              }}
            >
              <a
                href="https://reviews.financesonline.com/p/a-to-z-dispatch/"
                target="_blank"
              >
                <img
                  style={{
                    width: "150px",
                    height: "100px",
                    objectFit: "contain",
                    filter: "invert(1)",
                  }}
                  src={rStar}
                  alt=""
                />
              </a>
            </div>
            <div
              className="sf-root"
              data-id="3707150"
              data-badge="light-default"
              data-variant-id="sf"
              style={{
                display: "block",
                textAlign: "left",
                width: "75px",
              }}
            >
              <a
                href="https://sourceforge.net/software/product/A-to-Z-Dispatch/"
                target="_blank"
                rel="noreferrer"
              >
                AtoZ Dispatch Reviews
              </a>
            </div>
          </div>

          <div style={{ marginTop: "20px" }} className="right-part">
            <ul
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <li>
                {" "}
                <Link
                  target="_blank"
                  to="https://www.facebook.com/profile.php?id=61559798663626"
                >
                  {" "}
                  <img src={facebook} alt="facebook" />{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link target="_blank" to="https://twitter.com/taxi_web">
                  {" "}
                  <img src={twitter} alt="twitter" />
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  target="_blank"
                  to="https://www.youtube.com/channel/UCRcHPCq0SDF0LelKMbZI46Q"
                >
                  {" "}
                  <img src={youtube} alt="youtube" />
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  target="_blank"
                  to="https://www.instagram.com/atozdispatch"
                >
                  {" "}
                  <img src={instagram} alt="instagram" />{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link target="_blank" to="https://www.tiktok.com/@atozdispatch">
                  {" "}
                  <img src={tiktok} alt="tiktok" />{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/company/atozdispatch/"
                >
                  {" "}
                  <img src={linkedin} alt="linkedin" />{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  target="_blank"
                  to="https://www.pinterest.com/atozdispatch/?invite_code=066345f32ee44f2d806f567fb0e4babb&sender=1021472896641629964"
                >
                  {" "}
                  <>
                    <FaPinterest size={30} />
                  </>
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.trustpilot.com/review/atozdispatch.com"
                >
                  {" "}
                  <img
                    src={trustpilot}
                    alt="trustpilot"
                    style={{ width: "33px" }}
                  />{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-section help">
          <h4 style={{ textAlign: "right" }}>CONTACT</h4>
          <ul>
            <li style={{ textAlign: "right" }}>
              <a
                href={`mailto:support@atozdispatch.com`}
                className="text-white break-words font-bold"
              >
                support@atozdispatch.com
              </a>
            </li>
            <li style={{ textAlign: "right" }}>
              Whatsapp :
              <a
                target="_blank"
                href="https://wa.me/+9779810101016?text=Hello%2C%20I%20would%20like%20to%20chat%20with%20you"
                rel="noreferrer"
              >
                +977 981-0101016
              </a>
            </li>
            <li style={{ textAlign: "right" }}>
              Call/SMS :<a href={`tel:+19172591394`}>+1 ( 917 ) 259-1394</a>
            </li>
            <li style={{ textAlign: "right" }}>
              1111B{"   "}South Governors Avenue Dover, DE 19904 US
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {getCurrentYear()} All rights reserved.{" "}
          <a href="/">A To Z Dispatch </a>
        </p>
      </div>
    </section>
  );
};

export default NewFooter;
