import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { postRequest } from "../Helpers/APIHelper";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@material-ui/core";
import playIcon from "../assets/image/play-circle.svg";
import introVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM.mp4";
import { Link, useNavigate } from "react-router-dom";
import { IoMdPlay } from "react-icons/io";
import standard from "../assets/image/standard-1.jpg";
import premiuim from "../assets/image/premium-1.jpg";
import pricingPlanData from "./const/pricingPlanData";

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleClose = () => {
    setShow(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
  };

  const handleShow = () => {
    setMessage(false);
    setShow(true);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitDemo = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("request-a-demo", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          setMessage(true);
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleCloseVideoModal = () => setShowVideoModal(false);
  const handleShowVideoModal = () => setShowVideoModal(true);

  const showModal = (link) => {
    handleShow();
  };

  return (
    <>
      <div className="faq" id="products">
        <h3> Products </h3>
        <div className="content">
          <p>
            White-label Dispatch System: Logo Customization: Purchase includes
            the option to change the logo on the application to align with your
            brand identity.
          </p>
          <p>
            Payment Gateway Flexibility, Location Services & White-label System
          </p>
          <p>
            Note*: The package does not include custom requirements such as
            content, images, or additional features.
          </p>
        </div>
        {/* --video-section----- */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={handleShowVideoModal}
            className="header-outlined-btn"
          >
            <IoMdPlay size={20} />
            Watch Demo
          </button>
          <Modal
            show={showVideoModal}
            onHide={handleCloseVideoModal}
            className="full-modal"
          >
            <Button variant="secondary" onClick={handleCloseVideoModal}>
              X
            </Button>
            <video width="100%" height="100%" controls={true} autoPlay="true">
              <source src={introVideo} type="video/mp4" />
            </video>
          </Modal>
        </div>

        <Container>
          <Row>
            {pricingPlanData.map((pricingItem, index) => (
              <Col key={index} md={6} lg={4} className="pricing-table">
                <div className="item relative flex " bis_skin_checked="1">
                  <div className="bg-icons">
                    <div className="header-img-ctn">
                      <img
                        className="header-img"
                        src={pricingItem.image}
                        alt={pricingItem.title}
                      />
                    </div>
                  </div>
                  <div className="title-ctn">
                    <span className="title">{pricingItem.title}</span>
                    <p className="sub-title">{pricingItem.description}</p>
                  </div>
                  <div className="price-section">
                    <label className="p1">{pricingItem.price}</label>
                    <label className="p2">/MONTH</label>
                    {/* <label className="p3">
                      +One-time Set up fee {pricingItem.setupFee}
                    </label> */}
                  </div>
                  <Link to={"/signup"}>
                    <div className="req-ctn">
                      <button className="outlined-btn">
                        SIGN UP FOR FREE TRIAL
                      </button>
                    </div>
                  </Link>
                  <div className="plan-ctn">
                    {pricingItem.features.map((feature, index) => (
                      <label key={index} className="flex-justify-center">
                        <div>
                          <img
                            className="img-icon"
                            src="/tick.svg"
                            alt="tick"
                          />
                        </div>
                        {feature}
                      </label>
                    ))}
                  </div>
                  {/* <Link target="_blank" to={pricingItem.orderLink}>
                    <div className="req-ctn absolute">
                      <button className="filled-btn">SIGN UP</button>
                    </div>
                  </Link> */}
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        {/* ===========modal-for-request-demo=========== */}
        <Modal show={show} className="centered-modal" onHide={handleClose}>
          <Modal.Header>
            <a href="#!" className="close-modal" onClick={handleClose}>
              Close
            </a>
            {message ? (
              <Modal.Title>Congratulation!</Modal.Title>
            ) : (
              <Modal.Title>Request a Demo</Modal.Title>
            )}
          </Modal.Header>
          {message ? (
            <div className="success-message">
              We have sent you all the demo credentials for Backend, Android and
              IOS passenger and driver app. Please kindly check your inbox or
              spam
            </div>
          ) : (
            <Form onSubmit={handleSubmitDemo}>
              <Modal.Body>
                <div className="single-banner-card">
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="number"
                          placeholder="Contact Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <ReCAPTCHA
                    sitekey="6Ldoys0iAAAAAKMK-xJT3IntXQFFcIgWotiVhaKG"
                    onChange={handleCaptchaChange}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" type="submit" disabled={btnDisable}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Modal>
      </div>
    </>
  );
};
export default SubscriptionPlan;
